import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalContactCenterComponent } from '@components/modal-contact-center/modal-contact-center.component';
import { ModalContactResultComponent } from '@components/modal-contact-result/modal-contact-result.component';
import { ModalContactComponent } from '@components/modal-contact/modal-contact.component';
import { SOCIAL_URL } from '@constants/static-link.constant';
import { ModalResponseContact, ModalType } from '@interfaces/help-button.interface';
import { BUTTONS_TITLE } from '@pages-content/help-button.constant';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { VitaliaService } from '@services/vitalia/vitalia.service';
import { ErrorUtils } from '@utils/error.utils';

declare let Genesys: Function;

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
})
export class HelpButtonComponent implements OnInit {
  public optionsOpened = false;
  public buttonsTitle = BUTTONS_TITLE;
  public socialNetworks: Array<string>;
  public showVitaliaIcon: boolean;
  @Output() public menuStatus = new EventEmitter<boolean>();

  constructor(
    public font: FontService,
    private navService: NavigationService,
    private modalService: ModalService,
    private errorUtils: ErrorUtils,
    private vitaliaService: VitaliaService
  ) {
    const socialNetworks = Object.keys(SOCIAL_URL);
    this.socialNetworks = socialNetworks.filter(
      (socialNetwork) => (socialNetwork !== 'whatsapp' && socialNetwork !== 'email')
    );
  }

  ngOnInit(): void {
    this.vitaliaService.vitaliaStatusObs().subscribe((response: boolean) => {
      this.showVitaliaIcon = response;
    });
  }

  public toggleOptions(): void {
    this.optionsOpened = !this.optionsOpened;
    this.menuStatus.emit(this.optionsOpened);
  }

  public openModal(type: ModalType): void {
    this.toggleOptions();
    const isContactModal = type === 'contact';
    const component = isContactModal ? ModalContactComponent : ModalContactCenterComponent;
    const callback = isContactModal ? (value) => this.callbackContactModal(value) : null;
    this.modalService.openModal(component, null, 'modal-default', callback);
  }

  public callbackContactModal(value: ModalResponseContact) {
    const { type, data } = value;
    const isSuccessResponse = type === 'success';
    return isSuccessResponse ?
      this.modalService.openModal(ModalContactResultComponent, data) :
      this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(data) });
  }

  public goToUrl(url: string): void {
    this.navService.goTo(url);
    this.toggleOptions();
  }

  public goToEmail(): void {
    this.navService.windowOpen(SOCIAL_URL['email'], '_self');
  }

  public goToSocialNetwork(type: string): void {
    this.goToUrl(SOCIAL_URL[type]);
  }


  public displayWebchat(): void {
    Genesys('subscribe', 'Messenger.ready', () => {
      Genesys('command', 'Messenger.open', {}, () => {

      });
    });
    this.toggleOptions();
  }

}
