import { Mockup } from '@interfaces/mockup.interface';
import { TransferState } from '@interfaces/transfer-state.interface';
import { environment } from '@environment';

// tslint:disable:max-line-length
export const GET_TRANSFER_STATE_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        header: {
          id: 1,
          pagina: 'Estado de transferencia',
          imagen: 15,
          icono: 'handshake'
        },
        articulos: [
          {
            articulo_id: 1
          },
          {
            articulo_id: 2
          },
          {
            articulo_id: 3
          }
        ],
        traducciones: [
          {
            id: 1,
            languages_code: 'es',
            estado_de_transferencia: 1,
            titulo: '<p>Conoce en qu&eacute; estado se encuentra <strong>tu Solicitud.</strong></p>',
            subtitulo: '<p>Si ya iniciaste tu proceso de traspaso online, est&aacute;s a un paso de ser parte de AFP PlanVital.</p>',
            body: '<p><strong>&iquest;Tienes dudas sobre el proceso de traspaso?</strong><br />A continuaci&oacute;n te explicamos el significado de cada uno de los estados de tu traspaso.</p>\n<ul>\n<li><strong>Selecciona tus cuentas</strong>, aqu&iacute; puedes traspasar tanto tus cuentas obligatorias como tus ahorros voluntarios, cambiarlas de fondo y distribuirlas.</li>\n<li><strong>Valida tus datos personales</strong>, en tu AFP actual est&aacute;n tus datos personales, aqu&iacute; debes corroborarlos y adem&aacute;s puedes actualizarlos.</li>\n<li><strong>Valida los datos de tu empleador</strong>, al igual que tus datos personales, los datos de tu empleador pueden variar de tu situaci&oacute;n actual, actual&iacute;zalos de ser necesario.</li>\n<li><strong>Validaci&oacute;n de c&oacute;digo PIN</strong>, es el &uacute;ltimo paso antes de completar el traspaso, recibir&aacute;s un SMS a tu tel&eacute;fono m&oacute;vil con el c&oacute;digo para finalizar el proceso.</li>\n<li><strong>Confirmar traspaso</strong>, aqu&iacute; haz finalizado el traspaso de fondos desde tu AFP anterior hacia AFP PlanVital, recuerda revisar el resumen de tu traspaso.</li>\n</ul>',
            titulo_formulario: 'Para revisar el estado de tú solicitud completa los datos.',
            rut: 'Ingresa tu RUT',
            fecha: 'Ingrese la fecha de Solicitud',
            boton_formulario: 'Enviar',
            traspaso_texto: '<p>&iquest;A&uacute;n no comienzas tu <strong>traspaso online?</strong></p>',
            traspaso_boton: 'Ir al traspaso online',
            articulos_titulo: '<p>Descubre los beneficios de <strong>ser parte de AFP PlanVital</strong></p>',
            articulos_texto: '<p>Si necesitas m&aacute;s informaci&oacute;n, <strong>cont&aacute;ctanos en nuestros medios de atenci&oacute;n.</strong></p>',
            articulos_boton: 'Ir al centro de atención'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const TRANSFER_STATE_MOCKUP: TransferState = {
  id: 1,
  header: {
    image: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: null,
    },
    icon: 'handshake',
    firstLine: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>'
  },
  statusInformation: {
    title: '<p>Conoce en qu&eacute; estado se encuentra <strong>tu Solicitud.</strong></p>',
    subtitle: '<p>Si ya iniciaste tu proceso de traspaso online, est&aacute;s a un paso de ser parte de AFP PlanVital.</p>',
    body: '<p><strong>&iquest;Tienes dudas sobre el proceso de traspaso?</strong><br />A continuaci&oacute;n te explicamos el significado de cada uno de los estados de tu traspaso.</p>\n<ul>\n<li><strong>Selecciona tus cuentas</strong>, aqu&iacute; puedes traspasar tanto tus cuentas obligatorias como tus ahorros voluntarios, cambiarlas de fondo y distribuirlas.</li>\n<li><strong>Valida tus datos personales</strong>, en tu AFP actual est&aacute;n tus datos personales, aqu&iacute; debes corroborarlos y adem&aacute;s puedes actualizarlos.</li>\n<li><strong>Valida los datos de tu empleador</strong>, al igual que tus datos personales, los datos de tu empleador pueden variar de tu situaci&oacute;n actual, actual&iacute;zalos de ser necesario.</li>\n<li><strong>Validaci&oacute;n de c&oacute;digo PIN</strong>, es el &uacute;ltimo paso antes de completar el traspaso, recibir&aacute;s un SMS a tu tel&eacute;fono m&oacute;vil con el c&oacute;digo para finalizar el proceso.</li>\n<li><strong>Confirmar traspaso</strong>, aqu&iacute; haz finalizado el traspaso de fondos desde tu AFP anterior hacia AFP PlanVital, recuerda revisar el resumen de tu traspaso.</li>\n</ul>'
  },
  transferStatusBox: {
    title: 'Para revisar el estado de tú solicitud completa los datos.',
    rut: 'Ingresa tu RUT',
    date: 'Ingrese la fecha de Solicitud',
    button: 'Enviar'
  },
  transferBanner: {
    text: '<p>&iquest;A&uacute;n no comienzas tu <strong>traspaso online?</strong></p>',
    button: 'Ir al traspaso online'
  },
  articlesSection: {
    title: '<p>Descubre los beneficios de <strong>ser parte de AFP PlanVital</strong></p>',
    articles: [
      {
        id: 1,
        identifier: 'APV',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        title: 'Ahorro Previsional Voluntario APV',
        url: 'afiliado/ahorros/previsional-voluntario',
      },
      {
        id: 2,
        identifier: 'APS',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        title: 'Beneficios Aporte Solidario APS',
        url: 'pensionado/beneficios/pension-solidaria',
      },
      {
        id: 3,
        identifier: 'Cuenta 2',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        title: 'Ahorra con tu Cuenta 2',
        url: 'afiliado/ahorros/cuenta-2',
      }
    ],
    disclaimer: '<p>Si necesitas m&aacute;s informaci&oacute;n, <strong>cont&aacute;ctanos en nuestros medios de atenci&oacute;n.</strong></p>',
    disclaimerButton: 'Ir al centro de atención',
    disclaimerUrl: null
  }
};

export const GET_TRANSFER_STATE_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        header: {
          id: 1,
          pagina: 'Estado de transferencia',
          imagen: 15,
          icono: 'handshake'
        },
        articulos: [],
        traducciones: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const TRANSFER_STATE_NULL_MOCKUP: TransferState = {
  id: 1,
  header: {
    image: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'handshake',
    firstLine: null,
  },
  statusInformation: {
    title: null,
    subtitle: null,
    body: null,
  },
  transferStatusBox: {
    title: null,
    rut: null,
    date: null,
    button: null,
  },
  transferBanner: {
    text: null,
    button: null,
  },
  articlesSection: {
    title: null,
    articles: [],
    disclaimer: null,
    disclaimerButton: null,
    disclaimerUrl: null
  }
};

