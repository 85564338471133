import { Mockup } from '@interfaces/mockup.interface';
import { ValidateCertificate } from '@interfaces/validate-certificate.interface';

export const VALIDATE_CERTIFICATE_MOCKUP: Mockup = {
  success: [{
    response: {
      valid: true,
      data: 'base64',
    } as ValidateCertificate
  }],
  failures: [{
    response: {}
  }]
};

export const GET_CERTIFICATE_MOCKUP: Mockup = {
  success: [{
    response: {
      success: true
    }
  }],
  failures: [{
    response: {}
  }]
};
