/**
 * Constante para definir los textos que se deben mostrar con la etiqueta H1 en el banner de información.
 * El banner, dependiendo de la cantidad de elementos, puede desplegar una o más secciones, pero por reglas de SEO
 * solo debe existir un elemento H1. Los elementos indicados en esta constante mostrarán la etiqueta en el H1 del banner, en caso
 * contrario se utilizará la etiqueta P
 */
export const VIEW_WITH_H1_TAG: string[] = [
    'Consulta Solicitudes',
    'PENSIONER.ASSESSMENT.PAPERWORK.HEADER_FIRST_LINE',
    'EMPLOYER.SERVICES.EXCESS_PAYMENT.BANNER_FIRST_LINE',
    'EMPLOYER.SERVICES.PAYMENT_OPTIONS.BANNER_FIRST_LINE',
    'EMPLOYER.SERVICES.DEPOSITS.HEADER_TITLE',
    'EMPLOYER.SERVICES.PASSWORD.BANNER_FIRST_LINE',
    'EMPLOYER.PAYMENT.SHEETS.BANNER_FIRST_LINE',
    'EMPLOYER.PAYMENT.FEES.HEADER_TITLE',
    'EMPLOYER.INFO.EMPLOYEE_TYPES.HEADER_TITLE',
    'EMPLOYER.INFO.YOUNG_EMPLOYEE.BANNER.TITLE',
    'EMPLOYER.INFO.CONTRIBUTIONS-PAYMENT-NOV.HEADER_TITLE',
    '<strong>Administración</strong>',
    'CORPORATION.POLICIES.AFP.HEADER_TITLE',
    'CORPORATION.POLICIES.GOVERNMENT.HEADER_TITLE',
    'CORPORATION.RELEVANT_INFO.ANNUAL_MEMORY.HEADER_TITLE',
    '<strong>Mapa del sitio</strong>',
    'CORPORATION.RELEVANT_INFO.INFORMATION_OF_INTEREST.HEADER_TITLE',
    'CORPORATION.RELEVANT_INFO.ESSENTIAL_FACTS.HEADER_TITLE',
    'CORPORATION.RELEVANT_INFO.FINANCIAL_STATEMENTS.HEADER_FIRST_LINE',
    'AFFILIATE.ASSESSMENT.PAPERWORK_CENTER.HEADER_FIRST_LINE',
    'AFFILIATE.ASSESSMENT.CERTIFICATES.TITLE',
    '<p><span class="ui-provider a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak" dir="ltr">&iquest;' +
    'Por qu&eacute; cambiarme a AFP PlanVital?</span></p>',
    '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital&nbsp;</strong></p>',
    'Proyecto retiro 10% de los Fondos de Pensiones',
    'AFFILIATE.ASSESSMENT.SIMULATORS.INVESTOR_PROFILE.HEADER.FIRST_LINE',
    '<p>Multifondos</p>',
    '<p>Informaci&oacute;n <strong>F</strong><strong>inanciera</strong></p>',
    'AFFILIATE.ASSESSMENT.PAPERWORK_CENTER.TERMINAL_ILLNESS.HOME.BANNER.FIRST_LINE',
    'AFFILIATE.ASSESSMENT.PAPERWORK_CENTER.CAI.HOME.BANNER.FIRST_LINE',
    'AFFILIATE.SAVE_WITH_US.VOLUNTARY_SAVINGS.HEADER.TITLE',
    'AFFILIATE.SAVE_WITH_US.MANDATORY_SAVINGS.HEADER.TITLE',
    'AFFILIATE.SAVE_WITH_US.SECONDARY_ACCOUNT.HEADER.TITLE',
    '<p>Asignaci&oacute;n familiar y renta tope</p>',
  ];
