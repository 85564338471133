<ion-fab *ngIf="showVitaliaIcon" class="fab-container" vertical="bottom" horizontal="end" slot="fixed">
  <ion-fab-button class="principal-button contact-button" [activated]="optionsOpened" (click)="toggleOptions()"
    title="{{ buttonsTitle?.contactCenter | translate }}">
    <div class="i-customer-service"></div>
  </ion-fab-button>
  <div class="help-button-container" [class.open]="optionsOpened">
    <div class="tools-container" [class.open]="optionsOpened">
      <div class="row-container first-row">
        <div class="container-button" (click)="goToSocialNetwork('whatsapp')">
          <div class="help-button whatsapp" title="{{ buttonsTitle?.whatsapp | translate }}">
            <div class="icon i-whatsapp"></div>
          </div>
        </div>
        <div class="help-description whatsapp" [ngClass]="font?.size">{{ buttonsTitle?.whatsapp | translate }}</div>
      </div>
      <div class="row-container">
        <div class="container-button" (click)="openModal('call-center')">
          <div class="help-button" title="{{ buttonsTitle?.contactCenter | translate }}">
            <div class="icon i-phone"></div>
          </div>
        </div>
        <div class="help-description" [ngClass]="font?.size">{{ buttonsTitle?.contactCenter | translate }}</div>
      </div>
      <div class="row-container">
        <div class="container-button" (click)="displayWebchat()">
          <div class="help-button" title="{{ buttonsTitle?.webchat | translate }}">
            <div class="icon i-webchat"></div>
          </div>
        </div>
        <div class="help-description" [ngClass]="font?.size">{{ buttonsTitle?.webchat | translate }}</div>
      </div>
    </div>

  </div>
</ion-fab>