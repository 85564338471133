import { Component, OnInit } from '@angular/core';
import { ModalAppStoresComponent } from '@components/modal-app-stores/modal-app-stores.component';
import { ModalContactCenterComponent } from '@components/modal-contact-center/modal-contact-center.component';
import { ModalContactResultComponent } from '@components/modal-contact-result/modal-contact-result.component';
import { ModalContactComponent } from '@components/modal-contact/modal-contact.component';
import { SOCIAL_MEDIA_CHANNELS } from '@constants/contact-channels.constant';
import { SOCIAL_URL } from '@constants/static-link.constant';
import { environment } from '@environment';
import { ModalResponseContact, ModalType } from '@interfaces/help-button.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-contact-channels',
  templateUrl: './contact-channels.component.html',
  styleUrls: ['./contact-channels.component.scss'],
})
export class ContactChannelsComponent {

  public socialMediaChannels = SOCIAL_MEDIA_CHANNELS;

  public branchesPage = environment.urlLocalizatorBranchStores;

  public get isMobile(): boolean { return this.utils.isMobile; }

  public get mobileFirstRow(): any[] { return this.socialMediaChannels.slice(0, 2); }

  public get mobileSecondRow(): any[] { return this.socialMediaChannels.slice(2, this.socialMediaChannels.length); }

  constructor(
    private navService: NavigationService,
    private modalService: ModalService,
    private errorUtils: ErrorUtils,
    private utils: Utils,
    public font: FontService,
  ) { }

  public goToSocialNetwork(url: string): void {
    url === 'whatsapp' ? this.openWindow(SOCIAL_URL.whatsapp) : this.openWindow(url);
  }

  public goToUrl(url: string): void {
    this.navService.goTo(url);
  }

  public openWindow(url: string): void {
    this.navService.windowOpen(url);
  }

  public goToEmail(): void {
    this.navService.windowOpen(SOCIAL_URL['email'], '_self');
  }

  public openModal(type: ModalType): void {
    let component: any;
    let callback: any;
    switch (type) {
      case 'contact':
        component = ModalContactComponent;
        callback = (value) => this.callbackContactModal(value);
        break;
      case 'call-center':
        component = ModalContactCenterComponent;
        break;
      case 'app-stores':
        component = ModalAppStoresComponent;
        break;
    }

    this.modalService.openModal(component, null, 'modal-default', callback);
  }

  public callbackContactModal(value: ModalResponseContact) {
    const { type, data } = value;
    const isSuccessResponse = type === 'success';
    return isSuccessResponse ?
      this.modalService.openModal(ModalContactResultComponent, data) :
      this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(data) });
  }

  public iconSocialNetwork(socialNetwork: string): string {
    return `i-new-${socialNetwork}`;
  }

}
