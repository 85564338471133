import { Component, OnInit } from '@angular/core';
import { PENSION_PAYMENT_PAGES, PENSION_PAYMENT_TABS } from '@constants/pension-payment.constant';
import { HOME, PENSIONER_PAYMENT_TITLES } from '@constants/titles.constant';
import { BannerStyle } from '@interfaces/banner.interface';
import { FuturePensioner } from '@interfaces/future-pensioner.interface';
import { PrimaryOption } from '@interfaces/header.interface';
import { CardPage } from '@interfaces/info-card.interface';
import { PensionPayment } from '@interfaces/pension-payment.interface';
import { NavController } from '@ionic/angular';
import { PENSION_PAYMENT_CONTENT } from '@pages-content/pension-payment.constant';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-pension-payment',
  templateUrl: './pension-payment.page.html',
})
export class PensionPaymentPage implements OnInit {
  public pensionPayment: PensionPayment = PENSION_PAYMENT_CONTENT;
  public cardPage: CardPage = 'pensioner';
  public futurePensioner: FuturePensioner;
  public tabs: Array<PrimaryOption> = PENSION_PAYMENT_TABS;
  public breadcrumb = [HOME, PENSIONER_PAYMENT_TITLES.name];
  public bannerStyle: BannerStyle = 'primary';
  public showCalendarTab = true;
  public loading = false;
  constructor(
    protected cmsService: CmsService,
    protected navCtrl: NavController,
  ) { }

  public get calendarTabUrl(): string { return PENSION_PAYMENT_PAGES.calendar.url; }

  public ngOnInit(): void {
    this.getFuturePensioner();
  }
  public goTo(url: string): void {
    if (!url) { return; }
    this.showCalendarTab = this.calendarTabUrl === url;
    this.navCtrl.navigateForward(url);
  }

  private getFuturePensioner(): void {
    this.loading = true;
    this.cmsService.loadFuturePensioner().toPromise()
      .then((response) => this.futurePensioner = response)
      .finally(() => this.loading = false);
  }
}
