import { Component, Input, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { ModalComponent } from '@components/modal/modal.component';
import {
  ACCOUNT_TYPE_OPTIONS, MODAL_GET_CERTIFICATE_TEXT_CONTENT, PERIOD_CODE_OPTIONS,
  PRODUCT_TYPE_OPTIONS
} from '@constants/pages-content/modal-get-certificate.constants';
import { CertificateValues, QuotationsCertificateValues } from '@interfaces/certificates.interface';
import { FontService } from '@providers/font/font.service';
import { ValidateRut } from '@validators/rut.validator';
moment.locale('es-mx');
@Component({
  selector: 'app-modal-get-certificate',
  templateUrl: './modal-get-certificate.component.html',
  styleUrls: ['./modal-get-certificate.component.scss'],
})
export class ModalGetCertificateComponent extends ModalComponent {
  @Input() public certificateType: string;
  public textContent = MODAL_GET_CERTIFICATE_TEXT_CONTENT;
  public title: string;
  public type: string;
  public certificateForm: UntypedFormGroup;
  public rut = new UntypedFormControl('', [Validators.required, ValidateRut]);
  public periodCode = new UntypedFormControl('', Validators.required);
  public productType = new UntypedFormControl('', Validators.required);
  public accountType = new UntypedFormControl('', Validators.required);
  public fromDate = new UntypedFormControl(moment(), Validators.required);
  public toDate = new UntypedFormControl(moment(), Validators.required);
  public fields: string[];
  public periodCodeOptions = PERIOD_CODE_OPTIONS;
  public productTypeOptions = PRODUCT_TYPE_OPTIONS;
  public accountTypeOptions = ACCOUNT_TYPE_OPTIONS;
  public today = moment();
  public todayCalendar = new Date(Date.now());
  public startDateVar;
  public endDateVar;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    public translateService: TranslateService,
  ) {
    super(params, modalCtrl);
    this.title = this.params.get('title');
    this.type = this.params.get('type');
    this.fields = this.params.get('fields');
    this.createTransactionalForm();
  }

  public validForm(): boolean {
    switch (this.type) {
      case 'affiliate':
      case 'residence_records':
      case 'vacations':
        return this.rut.valid;
      case 'contingency_records':
        return this.rut.valid && this.accountType.valid;
      case 'quotations':
        return this.rut.valid && this.periodCode.valid && this.productType.valid && this.fromDate.valid && this.toDate.valid;
      case 'remunerations':
        return this.rut.valid && this.periodCode.valid;
    }
    return true;
  }

  private createTransactionalForm(): void {
    this.certificateForm = this.formBuilder.group({
      fromDate: [{ value: '', disabled: true }, [Validators.required]],
      toDate: [{ value: '', disabled: true }, [Validators.required]],
    }, {
    });
  }

  public getCertificate(): void {
    this.setDatesPeriod(this.periodCode.value);
    if (!this.validForm()) { return; }
    switch (this.type) {
      case 'quotations':
        if (!Number(this.periodCode.value)) {
          this.startDateVar = moment((this.certificateForm.get('fromDate').value as Date).toISOString()).format('YYYY-MM-DD');
          this.endDateVar = moment((this.certificateForm.get('toDate').value as Date).toISOString()).format('YYYY-MM-DD');
        }

        const quotationsCertificateValues = {
          rut: this.rut.value,
          startDate: this.startDateVar,
          endDate: this.endDateVar,
          productType: this.productType.value,
          accountType: this.accountType.value,
        } as QuotationsCertificateValues;
        this.closeModal(quotationsCertificateValues);
        break;
      default:
        const certificateValues = {
          rut: this.rut.value,
          periodCode: this.periodCode.value,
          productType: this.productType.value,
          accountType: this.accountType.value,
        } as CertificateValues;
        this.closeModal(certificateValues);
    }
  }

  public includeControl(controlName: string): boolean {
    return this.fields.includes(controlName);
  }

  private setDatesPeriod(months: string): void {
    if (!Number(months)) { return; }
    this.startDateVar = this.today.clone().add(-months, 'month').startOf('month').format('YYYY-MM-DD');
    this.endDateVar = this.today.clone().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  }

  public selectedPeriod(value): void {
    this.periodCode.setValue(value);
  }

  get customPeriodSelected(): boolean {
    return this.periodCode.value === 'H' && this.type === 'quotations';
  }

  get minFromDate(): Date {
    const minDate = new Date(1981, 0, 1);
    return minDate;
  }

  get minToDate(): Date {
    moment(this.certificateForm.get('fromDate').value);
    const minToDate = new Date(this.certificateForm.get('fromDate').value);
    return minToDate;
  }

  get fromDateControl(): AbstractControl { return this.fromDate; }

  get toDateControl(): AbstractControl { return this.toDate; }
}


