import { QuotaValueInformation } from '@interfaces/financial-information.interface';
import { FUND } from '@pages-content/general.constant';

const sectionKey = 'AFFILIATE.ASSESSMENT.FINANCIAL_INFORMATION';
const quotaValueKey = 'QUOTA_VALUE';

export const QUOTA_VALUE_INFORMATION: QuotaValueInformation = {
  definitionsSection: {
    title: `${sectionKey}.${quotaValueKey}.DEFINITIONS.TITLE`,
    subtitle: `${sectionKey}.${quotaValueKey}.DEFINITIONS.SUBTITLE`,
    body: `${sectionKey}.${quotaValueKey}.DEFINITIONS.BODY`,
  },
  commissionSection: {
    text: `${sectionKey}.${quotaValueKey}.COMMISSIONS.TEXT`,
  },
  profitabilitySection: {
    fund: FUND,
    chartTitle: `${sectionKey}.${quotaValueKey}.CHART_TITLE`,
  },
  quotaValueSearcherBox: {
    title: `${sectionKey}.${quotaValueKey}.SEARCH_BOX.TITLE`,
    startDate: `${sectionKey}.${quotaValueKey}.SEARCH_BOX.START_DATE`,
    endDate: `${sectionKey}.${quotaValueKey}.SEARCH_BOX.END_DATE`,
    button: `${sectionKey}.${quotaValueKey}.SEARCH_BOX.BUTTON`,
    excelButton: `${sectionKey}.${quotaValueKey}.SEARCH_BOX.EXCEL_BUTTON`,
    modalTitle: `${sectionKey}.${quotaValueKey}.SEARCH_BOX.MODAL_TITLE`,
  },
  notesSection: {
    notesTitle: `${sectionKey}.${quotaValueKey}.NOTES.TITLE`,
    notesLeft: `${sectionKey}.${quotaValueKey}.NOTES.NOTES_LEFT`,
    notesRight: `${sectionKey}.${quotaValueKey}.NOTES.NOTES_RIGHT`,
  },
  profitabilityDataFunds: [
    { year: 2002, fundA: 1.6, fundB: -0.38, fundC: 3.17, fundD: -0.84, fundE: 8.58 },
    { year: 2003, fundA: 25.24, fundB: 15.37, fundC: 10.37, fundD: 7.92, fundE: 2.4 },
    { year: 2004, fundA: 11.94, fundB: 9.68, fundC: 8.99, fundD: 6.91, fundE: 5.02 },
    { year: 2005, fundA: 9.95, fundB: 6.94, fundC: 4.46, fundD: 2.61, fundE: 0.5 },
    { year: 2006, fundA: 23.13, fundB: 19.7, fundC: 16.02, fundD: 11.77, fundE: 7.14 },
    { year: 2007, fundA: 8.43, fundB: 7.83, fundC: 5.51, fundD: 3.43, fundE: 1.28 },
    { year: 2008, fundA: -38.99, fundB: -28.83, fundC: -17.66, fundD: -8.88, fundE: -1.16 },
    { year: 2009, fundA: 44.19, fundB: 32.6, fundC: 20.89, fundD: 13.62, fundE: 6.57 },
    { year: 2010, fundA: 11.63, fundB: 10.52, fundC: 9.27, fundD: 5.91, fundE: 5.49 },
    { year: 2011, fundA: -10.57, fundB: -7.25, fundC: -3.55, fundD: 0.23, fundE: 3.68 },
    { year: 2012, fundA: 6.84, fundB: 5.61, fundC: 5, fundD: 3.96, fundE: 2.95 },
    { year: 2013, fundA: 7.19, fundB: 4.76, fundC: 4.81, fundD: 5.44, fundE: 5.13 },
    { year: 2014, fundA: 8.3, fundB: 7.5, fundC: 7.48, fundD: 6.72, fundE: 5.74 },
    { year: 2015, fundA: 2.86, fundB: 1.95, fundC: 1.76, fundD: 1.92, fundE: 0.34 },
    { year: 2016, fundA: -1.62, fundB: -0.03, fundC: 1.2, fundD: 2.3, fundE: 3.55 },
    { year: 2017, fundA: 14.61, fundB: 10.86, fundC: 6.81, fundD: 2.45, fundE: 0.7 },
    { year: 2018, fundA: -5.75, fundB: -3.78, fundC: -0.91, fundD: 1.23, fundE: 3.06 },
    { year: 2019, fundA: 17.14, fundB: 14.29, fundC: 14.24, fundD: 12.13, fundE: 8.16 },
    { year: 2020, fundA: 1.36, fundB: 3.00, fundC: 3.57, fundD: 3.24, fundE: 3.22 },
    { year: 2021, fundA: 13.22, fundB: 7.47, fundC: -2.83, fundD: -9.85, fundE: -12.91 },
    { year: 2022, fundA: -20.46, fundB: -15.15, fundC: -8.32, fundD: 1.58, fundE: 8.39 },
    { year: 2023, fundA: 7.11, fundB: 6.05, fundC: 3.01, fundD: -0.25, fundE: -1.60 },
  ],
};
