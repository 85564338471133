import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnChanges {
  @Input() linesNumber: number;
  @Input() paragraphNumber: number;
  @Input() size = 2;

  public lines = [];
  public paragraphs = [];
  private classes = {
    1: 'xsmall',
    2: 'small',
    3: '',
    4: 'large',
  };

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.lines = new Array(changes.linesNumber.currentValue - 1);
    this.paragraphs = new Array(changes.paragraphNumber.currentValue);
  }

  public get sizeClass(): string {
    return this.classes[this.size];
  }

  public get lastLineSizeClass(): string {
    return this.size === 1 ? this.classes[1] : this.classes[this.size - 1];
  }

}
