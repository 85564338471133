import { Component, OnInit } from '@angular/core';
import { FEATURED_LINKS } from '@constants/pages-content/home.constant';
import { BannerStyle } from '@interfaces/banner.interface';
import { Article } from '@interfaces/components.interface';
import { Home } from '@interfaces/home.interface';
import { QuotaValuesBanner } from '@interfaces/quota-values-banner.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  public home: Home;
  public bannerStyle: BannerStyle = 'default';
  public quotaValuesBanner: QuotaValuesBanner;
  public featuredLinks = FEATURED_LINKS;
  public loading = false;

  public get articles() {
    return this.home ? this.home.articlesContainer.articles : [];
  }

  public get guiaVitalArticles() {
    return this.home ? this.home.guiaVitalContainer.articles : [];
  }

  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private utils: Utils,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit() {
    this.loadData();
    this.languageSubscription();
  }

  private async loadData() {
    this.loading = true;
    await Promise.all([
      this.cmsService.loadHome().toPromise(),
      this.cmsService.loadQuotaValuesBanner().toPromise(),
    ])
    .then(([home, quotaValuesBanner]) => {
      this.home = home;
      this.quotaValuesBanner = quotaValuesBanner;
    })
    .finally(() => this.loading = false);
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => this.loadData());
  }

  public primaryArticle(articlesArray: Array<Article>): Article {
    return this.utils.getPrimaryArticle(articlesArray);
  }

  public secondaryArticles(articlesArray: Array<Article>): Array<Article> {
    return this.utils.getSecondaryArticles(articlesArray);
  }
}
