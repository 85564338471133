import { ChartOptions, ChartType } from '@components/chartist/chartist.component';
import { ASSESSMENT_TITLES, HOME, SIMULATOR_TITLES } from '@constants/titles.constant';
import { InvestorProfile, InvestorProfileQuestion, InvestorProfileResult } from '@interfaces/investor-profile.interface';

const pageKey = 'AFFILIATE.ASSESSMENT.SIMULATORS.INVESTOR_PROFILE';
const pageMultiFundKey = 'AFFILIATE.ASSESSMENT.MULTI_FUNDS.FUNDS';

export const TEXT_CONTENT = {
  disclaimer: `${pageKey}.DISCLAIMER`,
  transferButtonText: `${pageKey}.TRANSFER_BUTTON_TEXT`,
  loginButtonText: `${pageKey}.LOGIN_BUTTON_TEXT`,
};

export const INVESTOR_PROFILE_UTILS = {
  startButtonText: `${pageKey}.START_BUTTON_TEXT`,
  processButtonText: `${pageKey}.PROCESS_BUTTON_TEXT`,
  nextButtonText: `${pageKey}.NEXT_BUTTON_TEXT`,
  backButtonText: `${pageKey}.BACK_BUTTON_TEXT`,
  disclaimer: `${pageKey}.RESULTS.DISCLAIMER`,
  newClientButtonText: `${pageKey}.NEW_CLIENT_BUTTON_TEXT`,
  changeFundButtonText: `${pageKey}.CHANGE_FUND_BUTTON_TEXT`,
  againButtonText: `${pageKey}.AGAIN_BUTTON_TEXT`,
  profitability: `${pageKey}.PROFITABILITY`,
  detailsProfile: `${pageKey}.PROFILE_DETAILS`
};

export const INVESTOR_RESULT_POPOVER = {
  title: `${pageKey}.RESULTS.POPOVER.TITLE`,
  description: `${pageKey}.RESULTS.POPOVER.DESCRIPTION`
};

export const INVESTOR_PROFILE_CONTENT: InvestorProfile = {
  header: {
    firstLine: `${pageKey}.HEADER.FIRST_LINE`,
    image: {
      alt: 'Descubre el mejor fondo para ti',
      url: 'assets/images/simulador_inversor1.jpg',
    },
  },
  headerSimulation: {
    firstLine: `${pageKey}.HEADER.FIRST_LINE`,
    image: {
      alt: 'Descubre el mejor fondo para ti',
      url: 'assets/images/simulador_inversor2.jpg',
    },
  },
  benefitsSection: {
    title: `${pageKey}.BENEFITS.TITLE`,
    subtitle: `${pageKey}.BENEFITS.SUBTITLE`,
    benefits: [
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.1`,
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.2`,
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.3`,
    ],
    buttonText: `${pageKey}.BENEFITS.BUTTON_TEXT`,
  },
  investorProfileBox: {
    title: `${pageKey}.BOX.TITLE`,
    description: `${pageKey}.BOX.DESCRIPTION`,
    fullName: `${pageKey}.BOX.FULLNAME`,
    rut: `${pageKey}.BOX.RUT`,
    email: `${pageKey}.BOX.EMAIL`,
    phone: `${pageKey}.BOX.PHONE`,
  }
};

export const INVESTOR_PROFILE_QUESTIONS: Array<InvestorProfileQuestion> = [
  {
    description: `${pageKey}.QUESTIONS.1.DESCRIPTION`,
    options: [
      {
        description: `${pageKey}.QUESTIONS.1.OPTIONS.1.DESCRIPTION`,
        value: 1,
      },
      {
        description: `${pageKey}.QUESTIONS.1.OPTIONS.2.DESCRIPTION`,
        value: 2,
      },
      {
        description: `${pageKey}.QUESTIONS.1.OPTIONS.3.DESCRIPTION`,
        value: 3,
      },
      {
        description: `${pageKey}.QUESTIONS.1.OPTIONS.4.DESCRIPTION`,
        value: 4,
      }
    ],
  },
  {
    description: `${pageKey}.QUESTIONS.2.DESCRIPTION`,
    options: [
      {
        description: `${pageKey}.QUESTIONS.2.OPTIONS.1.DESCRIPTION`,
        value: 4,
      },
      {
        description: `${pageKey}.QUESTIONS.2.OPTIONS.2.DESCRIPTION`,
        value: 3,
      },
      {
        description: `${pageKey}.QUESTIONS.2.OPTIONS.3.DESCRIPTION`,
        value: 2,
      },
      {
        description: `${pageKey}.QUESTIONS.2.OPTIONS.4.DESCRIPTION`,
        value: 1,
      },
    ],
  },
  {
    description: `${pageKey}.QUESTIONS.3.DESCRIPTION`,
    options: [
      {
        description: `${pageKey}.QUESTIONS.3.OPTIONS.1.DESCRIPTION`,
        value: 4,
      },
      {
        description: `${pageKey}.QUESTIONS.3.OPTIONS.2.DESCRIPTION`,
        value: 3,
      },
      {
        description: `${pageKey}.QUESTIONS.3.OPTIONS.3.DESCRIPTION`,
        value: 2,
      },
      {
        description: `${pageKey}.QUESTIONS.3.OPTIONS.4.DESCRIPTION`,
        value: 1,
      },
    ],
  },
  {
    description: `${pageKey}.QUESTIONS.4.DESCRIPTION`,
    options: [
      {
        description: `${pageKey}.QUESTIONS.4.OPTIONS.1.DESCRIPTION`,
        value: 3,
      },
      {
        description: `${pageKey}.QUESTIONS.4.OPTIONS.2.DESCRIPTION`,
        value: 2,
      },
      {
        description: `${pageKey}.QUESTIONS.4.OPTIONS.3.DESCRIPTION`,
        value: 1,
      },
    ],
  },
  {
    description: `${pageKey}.QUESTIONS.5.DESCRIPTION`,
    options: [
      {
        description: `${pageKey}.QUESTIONS.5.OPTIONS.1.DESCRIPTION`,
        value: 4,
      },
      {
        description: `${pageKey}.QUESTIONS.5.OPTIONS.2.DESCRIPTION`,
        value: 3,
      },
      {
        description: `${pageKey}.QUESTIONS.5.OPTIONS.3.DESCRIPTION`,
        value: 2,
      },
      {
        description: `${pageKey}.QUESTIONS.5.OPTIONS.4.DESCRIPTION`,
        value: 1,
      },
    ],
  },
  {
    description: `${pageKey}.QUESTIONS.6.DESCRIPTION`,
    options: [
      {
        description: `${pageKey}.QUESTIONS.6.OPTIONS.1.DESCRIPTION`,
        value: 1,
      },
      {
        description: `${pageKey}.QUESTIONS.6.OPTIONS.2.DESCRIPTION`,
        value: 2,
      },
      {
        description: `${pageKey}.QUESTIONS.6.OPTIONS.3.DESCRIPTION`,
        value: 3,
      },
    ],
  },
];

export const INVESTOR_PROFILE_RESULT: Array<InvestorProfileResult> = [
  {
    fund: 'A',
    description: `${pageKey}.RESULTS.FUNDS.A.DESCRIPTION`,
    profitability: `${pageKey}.RESULTS.FUNDS.A.PROFITABILITY_FUNDS`,
    score: 8,
    risk: `${pageMultiFundKey}.A.RISK`,
    riskDescription: `${pageMultiFundKey}.A.DESCRIPTION`,
    detailsDescription: `${pageKey}.RESULTS.FUNDS.A.DETAILS_DESCRIPTION`,
    chartTitle: `${pageKey}.RESULTS.FUNDS.A.CHART_TITLE`
  },
  {
    fund: 'B',
    description: `${pageKey}.RESULTS.FUNDS.B.DESCRIPTION`,
    profitability: `${pageKey}.RESULTS.FUNDS.B.PROFITABILITY_FUNDS`,
    score: 11,
    risk: `${pageMultiFundKey}.B.RISK`,
    riskDescription: `${pageMultiFundKey}.B.DESCRIPTION`,
    detailsDescription: `${pageKey}.RESULTS.FUNDS.B.DETAILS_DESCRIPTION`,
    chartTitle: `${pageKey}.RESULTS.FUNDS.B.CHART_TITLE`
  },
  {
    fund: 'C',
    description: `${pageKey}.RESULTS.FUNDS.C.DESCRIPTION`,
    profitability: `${pageKey}.RESULTS.FUNDS.C.PROFITABILITY_FUNDS`,
    score: 17,
    risk: `${pageMultiFundKey}.C.RISK`,
    riskDescription: `${pageMultiFundKey}.C.DESCRIPTION`,
    detailsDescription: `${pageKey}.RESULTS.FUNDS.C.DETAILS_DESCRIPTION`,
    chartTitle: `${pageKey}.RESULTS.FUNDS.C.CHART_TITLE`
  },
  {
    fund: 'D',
    description: `${pageKey}.RESULTS.FUNDS.D.DESCRIPTION`,
    profitability: `${pageKey}.RESULTS.FUNDS.D.PROFITABILITY_FUNDS`,
    score: 20,
    risk: `${pageMultiFundKey}.D.RISK`,
    riskDescription: `${pageMultiFundKey}.D.DESCRIPTION`,
    detailsDescription: `${pageKey}.RESULTS.FUNDS.D.DETAILS_DESCRIPTION`,
    chartTitle: `${pageKey}.RESULTS.FUNDS.D.CHART_TITLE`
  },
  {
    fund: 'E',
    description: `${pageKey}.RESULTS.FUNDS.E.DESCRIPTION`,
    profitability: `${pageKey}.RESULTS.FUNDS.E.PROFITABILITY_FUNDS`,
    score: 22,
    risk: `${pageMultiFundKey}.E.RISK`,
    riskDescription: `${pageMultiFundKey}.E.DESCRIPTION`,
    detailsDescription: `${pageKey}.RESULTS.FUNDS.E.DETAILS_DESCRIPTION`,
    chartTitle: `${pageKey}.RESULTS.FUNDS.E.CHART_TITLE`
  },
];

export const CONTROL_NAMES: { [key: number]: string } = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
};

export const INVESTOR_PROFILE_BREADCRUMB = [
  HOME,
  ASSESSMENT_TITLES.name,
  ASSESSMENT_TITLES.content.simulators,
  SIMULATOR_TITLES.content.investorProfile
];

export const CHART_TYPE: ChartType = 'Line';

export const CHART_OPTIONS: ChartOptions = {
  axisY: {
    showGrid: true,
    position: 'start',
    labelInterpolationFnc: (value) => `${value}%`,
  },
  axisX: {
    showGrid: false,
  },
  width: '100%',
  height: '100%',
  fullWidth: true,
  showPoint: false,
  lineSmooth: true
};
