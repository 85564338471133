import { SwiperOptions } from 'swiper/types/swiper-options';
import { AfterViewInit, Component, effect, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import Swiper from 'swiper';
import { SwiperContainer } from 'swiper/element';

import { SlidesComponent } from '@components/slides/slides.component';
import { Banner, BannerStyle, BannerType } from '@interfaces/banner.interface';

interface SwiperNativeEl {
  swiper: Swiper;
  initialize: () => void;
}

@Component({
  selector: 'app-slides-banner',
  templateUrl: './slides-banner.component.html',
  styleUrls: ['./slides-banner.component.scss'],
})
export class SlidesBannerComponent extends SlidesComponent {
  @ViewChild('swiperRef', { static: true }) public swiperRef: ElementRef<SwiperNativeEl>;
  @Input() public banners: Array<Banner>;
  @Input() public bannerStyle: BannerStyle;
  @Input() public loading = false;
  public bannerType: BannerType = 'slider';
  public index = 0;
  constructor() {
    super();
    effect(() => {
      const swiperEl = this.swiperRef.nativeElement as SwiperContainer;
      const swiperParams: SwiperOptions = {
        slidesPerView: 1,
        pagination: { el: '.swiper-pagination', clickable: true },
        loop: this.banners && this.banners.length > 1,
        autoplay: { delay: 6000 },
      };
      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
    });
  }

  public navigate(direction: 'previous' | 'next'): void {
    direction === 'next' ? this.swiperRef.nativeElement.swiper.slideNext() : this.swiperRef.nativeElement.swiper.slidePrev();
  }
}
