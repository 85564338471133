import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';

import { SwiperContainer } from 'swiper/element';

import { SlidesComponent } from '@components/slides/slides.component';

@Component({
  selector: 'app-slider-calendar-cards',
  templateUrl: './slider-calendar-cards.component.html',
  styleUrls: ['./slider-calendar-cards.component.scss'],
})
export class SliderCalendarCardsComponent extends SlidesComponent implements AfterViewInit {
  @ViewChild('swiperRef', { static: true }) public swiperRef: ElementRef<SwiperContainer>;
  @Input() dateClass: (date: Date) => MatCalendarCellCssClasses;
  @Input() dates: Array<Date>;
  private dateNow = new Date().getMonth();
  private currentMonth = (this.dateNow === 11 && new Date(`${new Date().getFullYear() + 1}-01-15`).getMonth() === 0 ? 0 : this.dateNow);

  constructor() {
    super();
  }

  public ngAfterViewInit(): void {
    this.slideOpts = {
      slidesPerView: 1.8,
      centeredSlides: true,
      loop: false,
      initialSlide: this.currentMonth,
      breakpoints: {
        720: {
          centeredSlides: false,
          slidesPerView: 2,
          initialSlide: this.currentMonth,
        },
        320: {
          centeredSlides: true,
          slidesPerView: 1.15,
          initialSlide: this.currentMonth,
        }
      },
    };
    Object.assign(this.swiperRef.nativeElement, this.slideOpts);
    this.swiperRef.nativeElement.swiper.slideTo(this.slideOpts.initialSlide, 0);
  }

  public showCalendar(indexMonth: number) {
    return indexMonth >= this.currentMonth - 1 && indexMonth <= this.currentMonth + 1;
  }

  public navigate(direction: 'previous' | 'next'): void {
    direction === 'next' ? this.swiperRef.nativeElement.swiper.slideNext() : this.swiperRef.nativeElement.swiper.slidePrev();
  }
}
