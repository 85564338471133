import { FuturePensioner } from '@interfaces/future-pensioner.interface';
import { HOME, ASSESSMENT_TITLES, } from '@constants/titles.constant';

const pageKey = 'AFFILIATE.ASSESSMENT.FUTURE_PENSIONER';

export const FUTURE_PENSIONER_CONTENT: FuturePensioner = {
  banners: null,
  commissionSection: {
    title: `${pageKey}.COMMISSIONS.TITLE`,
    description: `${pageKey}.COMMISSIONS.DESCRIPTION`,
  }
};

export const FUTURE_PENSIONER_BREADCRUMB = [HOME, ASSESSMENT_TITLES.name, ASSESSMENT_TITLES.content.futurePensioner];
