import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Element } from '@interfaces/general.interface';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent {
  @Input() public elements: Array<Element>;
  @Input() public selectedElementId: string | number;
  @Input() public highlightSelected = false;
  @Input() public chipType: 'concentrated' | 'extended' = 'extended';
  @Output() private selectedId = new EventEmitter<string | number>();
  constructor() { }

  public selectElement(id: string | number) {
    this.selectedElementId = id;
    this.selectedId.emit(id);
  }

  public selectedClass(id: string | number): boolean {
    return this.highlightSelected && this.selectedElementId === id;
  }

}
