import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistance-out-hours',
  templateUrl: './assistance-out-hours.component.html',
  styleUrls: ['./assistance-out-hours.component.scss'],
})
export class AssistanceOutHoursComponent {

  constructor() { }

}
