<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="contact-container">
    <div class="text-container">
      <p class="title" [ngClass]="font?.size">{{ content?.title | translate }}</p>
      <p class="subtitle" [ngClass]="font?.size">{{ content?.description | translate }}</p>
    </div>
    <div class="form-container">
      <form [formGroup]="form">
        <div class="row-container two-column">
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.reasonCode | translate }}</ion-label>
            <mat-select [formControl]="reasonCode">
              <mat-option *ngFor="let contactReason of contactReasons" [value]="contactReason.code">
                {{contactReason.reason}}
              </mat-option>
            </mat-select>
          </ion-item>
        </div>
        <div class="row-container three-columns">
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.name | translate }}(*)</ion-label>
            <ion-input formControlName="name" placeholder="Juan"></ion-input>
          </ion-item>
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.fatherLastName | translate }}(*)</ion-label>
            <ion-input formControlName="fatherLastName" placeholder="Perez"></ion-input>
          </ion-item>
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.motherLastName | translate }}</ion-label>
            <ion-input formControlName="motherLastName" placeholder="Perez"></ion-input>
          </ion-item>
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.rut | translate }}(*)</ion-label>
            <ion-input formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut"></ion-input>
          </ion-item>
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.email | translate }}(*)</ion-label>
            <ion-input formControlName="email" placeholder="email@planvital.cl"></ion-input>
          </ion-item>
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.phoneNumber | translate }}</ion-label>
            <ion-input formControlName="phoneNumber" placeholder="988887777"></ion-input>
          </ion-item>
        </div>
        <div class="row-container">
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.description | translate }}(*)</ion-label>
            <ion-input formControlName="description"></ion-input>
          </ion-item>
        </div>
        <div class="row-container two-column">
          <ion-item class="question-box">
            <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ fieldNames?.file | translate }}(*)</ion-label>
            <mat-select [formControl]="addedFile">
              <mat-option [value]="false">{{ contentOptions?.no | translate }}</mat-option>
              <mat-option [value]="true">{{ contentOptions?.yes | translate }}</mat-option>
            </mat-select>
          </ion-item>
          <div class="question-box file-box" *ngIf="addedFile.value">
            <input class="file-input" id="file" type="file" accept="application/pdf" (change)="onFileChange($event)"/>
            <label class="file-label button" [ngClass]="font?.size" for="file">{{ labelButtonFile | translate }}</label>
          </div>
        </div>
      </form>
    </div>
    <ion-button class="button back primary" [disabled]="form.invalid" [ngClass]="font?.size" (click)="sendFormData()">{{ contentOptions?.send | translate }}</ion-button>
  </div>
</div>
