import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';
import { FuturePensioner } from '@interfaces/future-pensioner.interface';

// tslint:disable:max-line-length
export const GET_FUTURE_PENSIONER_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        banners: [
          {
            id: 1,
            futuro_pensionado_id: 1,
            banner_id: 5
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FUTURE_PENSIONER_MOCKUP: FuturePensioner = {
  banners: [
    {
      id: 5,
      image: {
        url: `${environment.cmsApiUrl}1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
        alt: 'alt',
      },
      icon: 'save',
      firstLine: '<p>Si tu plan es <strong>ahorrar</strong></p>',
      secondLine: '<p><strong>Te podemos ayudar</strong></p>',
      button: 'Descubre cómo'
    }
  ],
  commissionSection: {
    title: 'Comisiones de asesores previsionales',
    description: `<p>Depende del tipo de la modalidad que hayas elegido.</p><p><strong>- Renta Vitalicia:</strong> La comisión máxima es de 2% del saldo con un tope de 60 UF. Para Retiros Programados,la comisión es de 1,2% con un tope de 36 UF.</p><p><strong>- Cambio de modalidad de pensión (Retiro Programado a Renta Vitalicia):</strong> La comisión es de 2% con un tope de 60 UF.</p>`,
  }
};

export const GET_FUTURE_PENSIONER_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        banners: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FUTURE_PENSIONER_NULL_MOCKUP: FuturePensioner = {
  banners: [],
  commissionSection: {
    title: 'Comisiones de asesores previsionales',
    description: `<p>Depende del tipo de la modalidad que hayas elegido.</p><p><strong>- Renta Vitalicia:</strong> La comisión máxima es de 2% del saldo con un tope de 60 UF. Para Retiros Programados,la comisión es de 1,2% con un tope de 36 UF.</p><p><strong>- Cambio de modalidad de pensión (Retiro Programado a Renta Vitalicia):</strong> La comisión es de 2% con un tope de 60 UF.</p>`,
  }
};
