import { NgModule } from '@angular/core';
import { ModalAlertComponent } from '@components/modal-alert/modal-alert.component';
import { ModalGenericComponent } from '@components/modal-generic/modal-generic.component';
import { DigitsOnlyDirective } from '@directives/digits-only/digits-only.directive';
import { FormatRutDirective } from '@directives/format-rut/format-rut.directive';
import { ConfirmExitDirective } from './confirm-exit/confirm-exit.directive';

@NgModule({
  exports: [
    DigitsOnlyDirective,
    FormatRutDirective,
    ConfirmExitDirective,
  ],
  declarations: [
    DigitsOnlyDirective,
    FormatRutDirective,
    ConfirmExitDirective,
  ]
})
export class DirectivesModule { }
