import { FeaturedLinks } from '@interfaces/home.interface';
import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.HOME.ARTICLES';

export const FEATURED_LINKS: FeaturedLinks = {
  articles: [
    {
      url: 'https://www.misbeneficiosafp.cl/',
      image: {
        alt: 'Mis beneficios AFP',
        url: 'assets/images/05_qmn-mis-beneficios.webp',
      },
      title: `${pageKey}.1.TITLE`,
    },
    {
      url: 'https://www.spensiones.cl/portal/institucional/594/alt-propertyvalue-9897.html',
      image: {
        alt: 'Preguntas frecuentes sobre las AFP',
        url: 'assets/images/01_qmn-preguntas-frecuentes-afp.webp',
      },
      title: `${pageKey}.3.TITLE`,
    },
    {
      url: 'https://www.sontuslucas.cl/#/',
      image: {
        alt: 'Son tus lucas',
        url: 'assets/images/02_qmn-sontuslucas.webp',
      },
      title: `${pageKey}.4.TITLE`,
    },
    {
      url: 'https://afiliadoinformado.cl/',
      image: {
        alt: 'Afiliado informado',
        url: 'assets/images/03_qmn-afiliado-informado.webp',
      },
      title: `${pageKey}.2.TITLE`,
    },
    {
      url: 'https://img.portalafp.cl/login?next=/',
      image: {
        alt: 'Ingreso Minimo Garantizado',
        url: 'assets/images/04_qmn-ingreso-minimo-garantizado.webp',
      },
      title: `${pageKey}.5.TITLE`,
    },
  ],
  moreInfoText: MORE_INFO,
};
