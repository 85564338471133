import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';
import { SecondaryAccount } from '@interfaces/savings.interface';

// tslint:disable:max-line-length
export const GET_SECONDARY_ACCOUNT_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: {
          filename_disk: `9abf0c1f-0550-4491-862c-b95cac6f725b.png`,
        },
        traducciones: [{
          diferencias: '<p><strong>Debes solicitar y/o retirar un giro en una Sucursal,</strong> debes tener tu C&eacute;dula de Identidad vigente y para solicitarlo a trav&eacute;s del sitio, necesitas tu Clave de Seguridad.</p>\n<p>No puedes realizar giros de tu Cuenta de Ahorro Voluntario en los siguientes casos:</p>\n<ul>\n<li>Si est&aacute;s en proceso de traspaso de AFP, o solo de la Cuenta de Ahorro. Desde que se acepta el traspaso, hasta el d&iacute;a h&aacute;bil siguiente a la acreditaci&oacute;n del saldo en la cuenta (15 d&iacute;as aproximadamente).</li>\n<li>Si tienes una Solicitud de Retiro en proceso. Debes esperar que se cierre el proceso</li>\n<li>No debes superar los 24 giros en el a&ntilde;o calendario</li>\n<li>Mantener la cuenta bloqueada por aviso de C&eacute;dula de Identidad por extrav&iacute;o, hurto o robo.</li>\n</ul>',
          dudas: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar?</strong><br />Giro anticipado: En nuestro sitio o en nuestra red de sucursales.<br />Giro diferido: En nuestras Sucursales y a trav&eacute;s del Sitio Web.</li>\n<li><strong>&iquest;Cu&aacute;les son las formas de pago?</strong><br />Giro anticipado: Efectivo. Disponibilidad Inmediata.<br />Giro diferido: Cheque. Dep&oacute;sito en Cuenta Bancaria (unipersonal)</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />Giro anticipado: M&aacute;ximo el 85% del saldo ahorrado en tu cuenta y un monto m&aacute;ximo de $100.000.<br />Giro diferido: El total del saldo ahorrado en la cuenta, menos la comisi&oacute;n por administraci&oacute;n que corresponde.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />Giro anticipado: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.<br />Giro diferido: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n</ul>',
          languages_code: 'es',
        }],
        carrusel: [{
          infografias_id: 1
        },
        {
          infografias_id: 2
        },
        {
          infografias_id: 3
        }]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

const pageKey = 'AFFILIATE.SAVE_WITH_US.SECONDARY_ACCOUNT';

export const SECONDARY_ACCOUNT_MOCKUP: SecondaryAccount = {
  header: {
    image: {
      url: `${environment.cmsApiUrl}assets/9abf0c1f-0550-4491-862c-b95cac6f725b.png`,
      alt: undefined,
      title: undefined,
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  button: `${pageKey}.BUTTON`,
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    benefits: [
      {
        id: 1,
        icon: 'lupa',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 2,
        icon: 'client',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 3,
        icon: 'support',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      }
    ],
  },
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_producto.jpg',
          alt: 'Cuenta de ahorro, producto'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_proque_ahorrar.jpg',
          alt: 'Cuenta de ahorro, ahorrar'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_tipos_depositos.jpg',
          alt: 'Cuenta de ahorro, tipos de depósitos'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  depositsSection: {
    title: `${pageKey}.DEPOSITS.TITLE`,
    body: `${pageKey}.DEPOSITS.BODY`,
  },
  withdrawalsSection: {
    title: `${pageKey}.WITHDRAWALS.TITLE`,
    subtitle: `${pageKey}.WITHDRAWALS.SUBTITLE`,
    body: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar?</strong><br />Giro anticipado: En nuestro sitio o en nuestra red de sucursales.<br />Giro diferido: En nuestras Sucursales y a trav&eacute;s del Sitio Web.</li>\n<li><strong>&iquest;Cu&aacute;les son las formas de pago?</strong><br />Giro anticipado: Efectivo. Disponibilidad Inmediata.<br />Giro diferido: Cheque. Dep&oacute;sito en Cuenta Bancaria (unipersonal)</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />Giro anticipado: M&aacute;ximo el 85% del saldo ahorrado en tu cuenta y un monto m&aacute;ximo de $100.000.<br />Giro diferido: El total del saldo ahorrado en la cuenta, menos la comisi&oacute;n por administraci&oacute;n que corresponde.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />Giro anticipado: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.<br />Giro diferido: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n</ul>',
  },
  restrictionsSection: {
    title: `${pageKey}.RESTRICTIONS.TITLE`,
    body: '<p><strong>Debes solicitar y/o retirar un giro en una Sucursal,</strong> debes tener tu C&eacute;dula de Identidad vigente y para solicitarlo a trav&eacute;s del sitio, necesitas tu Clave de Seguridad.</p>\n<p>No puedes realizar giros de tu Cuenta de Ahorro Voluntario en los siguientes casos:</p>\n<ul>\n<li>Si est&aacute;s en proceso de traspaso de AFP, o solo de la Cuenta de Ahorro. Desde que se acepta el traspaso, hasta el d&iacute;a h&aacute;bil siguiente a la acreditaci&oacute;n del saldo en la cuenta (15 d&iacute;as aproximadamente).</li>\n<li>Si tienes una Solicitud de Retiro en proceso. Debes esperar que se cierre el proceso</li>\n<li>No debes superar los 24 giros en el a&ntilde;o calendario</li>\n<li>Mantener la cuenta bloqueada por aviso de C&eacute;dula de Identidad por extrav&iacute;o, hurto o robo.</li>\n</ul>',
  },
  commissionDisclaimer: {
    title: `${pageKey}.COMMISSION_DISCLAIMER.TITLE`,
    description: `${pageKey}.COMMISSION_DISCLAIMER.DESCRIPTION`
  }
};

export const GET_SECONDARY_ACCOUNT_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: null,
        traducciones: [
          {
            diferencias: '<p><strong>Debes solicitar y/o retirar un giro en una Sucursal,</strong> debes tener tu C&eacute;dula de Identidad vigente y para solicitarlo a trav&eacute;s del sitio, necesitas tu Clave de Seguridad.</p>\n<p>No puedes realizar giros de tu Cuenta de Ahorro Voluntario en los siguientes casos:</p>\n<ul>\n<li>Si est&aacute;s en proceso de traspaso de AFP, o solo de la Cuenta de Ahorro. Desde que se acepta el traspaso, hasta el d&iacute;a h&aacute;bil siguiente a la acreditaci&oacute;n del saldo en la cuenta (15 d&iacute;as aproximadamente).</li>\n<li>Si tienes una Solicitud de Retiro en proceso. Debes esperar que se cierre el proceso</li>\n<li>No debes superar los 24 giros en el a&ntilde;o calendario</li>\n<li>Mantener la cuenta bloqueada por aviso de C&eacute;dula de Identidad por extrav&iacute;o, hurto o robo.</li>\n</ul>',
            dudas: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar?</strong><br />Giro anticipado: En nuestro sitio o en nuestra red de sucursales.<br />Giro diferido: En nuestras Sucursales y a trav&eacute;s del Sitio Web.</li>\n<li><strong>&iquest;Cu&aacute;les son las formas de pago?</strong><br />Giro anticipado: Efectivo. Disponibilidad Inmediata.<br />Giro diferido: Cheque. Dep&oacute;sito en Cuenta Bancaria (unipersonal)</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />Giro anticipado: M&aacute;ximo el 85% del saldo ahorrado en tu cuenta y un monto m&aacute;ximo de $100.000.<br />Giro diferido: El total del saldo ahorrado en la cuenta, menos la comisi&oacute;n por administraci&oacute;n que corresponde.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />Giro anticipado: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.<br />Giro diferido: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n</ul>',
            languages_code: 'es',
          }
        ],
        carrusel: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const SECONDARY_ACCOUNT_NULL_MOCKUP: SecondaryAccount = {
  header: {
    image: {
      url: null,
      alt: undefined,
      title: undefined,
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  button: `${pageKey}.BUTTON`,
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    benefits: [],
  },
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_producto.jpg',
          alt: 'Cuenta de ahorro, producto'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_proque_ahorrar.jpg',
          alt: 'Cuenta de ahorro, ahorrar'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_tipos_depositos.jpg',
          alt: 'Cuenta de ahorro, tipos de depósitos'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  depositsSection: {
    title: `${pageKey}.DEPOSITS.TITLE`,
    body: `${pageKey}.DEPOSITS.BODY`,
  },
  withdrawalsSection: {
    title: `${pageKey}.WITHDRAWALS.TITLE`,
    subtitle: `${pageKey}.WITHDRAWALS.SUBTITLE`,
    body: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar?</strong><br />Giro anticipado: En nuestro sitio o en nuestra red de sucursales.<br />Giro diferido: En nuestras Sucursales y a trav&eacute;s del Sitio Web.</li>\n<li><strong>&iquest;Cu&aacute;les son las formas de pago?</strong><br />Giro anticipado: Efectivo. Disponibilidad Inmediata.<br />Giro diferido: Cheque. Dep&oacute;sito en Cuenta Bancaria (unipersonal)</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />Giro anticipado: M&aacute;ximo el 85% del saldo ahorrado en tu cuenta y un monto m&aacute;ximo de $100.000.<br />Giro diferido: El total del saldo ahorrado en la cuenta, menos la comisi&oacute;n por administraci&oacute;n que corresponde.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />Giro anticipado: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.<br />Giro diferido: El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n</ul>',
  },
  restrictionsSection: {
    title: `${pageKey}.RESTRICTIONS.TITLE`,
    body: '<p><strong>Debes solicitar y/o retirar un giro en una Sucursal,</strong> debes tener tu C&eacute;dula de Identidad vigente y para solicitarlo a trav&eacute;s del sitio, necesitas tu Clave de Seguridad.</p>\n<p>No puedes realizar giros de tu Cuenta de Ahorro Voluntario en los siguientes casos:</p>\n<ul>\n<li>Si est&aacute;s en proceso de traspaso de AFP, o solo de la Cuenta de Ahorro. Desde que se acepta el traspaso, hasta el d&iacute;a h&aacute;bil siguiente a la acreditaci&oacute;n del saldo en la cuenta (15 d&iacute;as aproximadamente).</li>\n<li>Si tienes una Solicitud de Retiro en proceso. Debes esperar que se cierre el proceso</li>\n<li>No debes superar los 24 giros en el a&ntilde;o calendario</li>\n<li>Mantener la cuenta bloqueada por aviso de C&eacute;dula de Identidad por extrav&iacute;o, hurto o robo.</li>\n</ul>',
  },
  commissionDisclaimer: {
    title: `${pageKey}.COMMISSION_DISCLAIMER.TITLE`,
    description: `${pageKey}.COMMISSION_DISCLAIMER.DESCRIPTION`
  }
};
