import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private navCtrl: NavController
  ) { }

  public externalUrl(url: string) {
    return url.includes('http');
  }

  public goTo(url: string) {
    window.scroll(0, 0);
    if (!url) { return; }
    if (this.externalUrl(url)) { return this.windowOpen(url, '_self'); }
    this.navCtrl.navigateForward(url);
  }

  public windowOpen(externalUrl: string, type = '_blank') {
    return window.open(externalUrl, type);
  }
}
