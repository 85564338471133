import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ModalInformationComponent
} from '@components/modal-information/modal-information.component';
import { MORE_INFO } from '@constants/pages-content/general.constant';
import { AlertBanner } from '@interfaces/components.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
})
export class AlertBannerComponent implements OnInit {
  @Input() public data: AlertBanner;
  @Input() public loading = false;
  @Output() public hideAlert = new EventEmitter<boolean>();
  public buttonText = MORE_INFO;

  public largeBody;
  public bodyStyle = 'small-body';
  public shorterText: boolean;
  public showMoreText = false;
  public shortenedBodyMessage = '';

  constructor(
    public font: FontService,
    private modalService: ModalService,
    private util: Utils
  ) { }

  public ngOnInit(): void {
    if (this.data.message) {
      this.verifyBodyLength(this.data.message);
    }
    this.updateShortenedBody();
    this.updateShowMoreText();
  }

  private updateShortenedBody(): void {
    if (this.data) {
      this.shortenedBodyMessage = this.shortenedBody(this.data.message);
    }
  }

  public openInformationModal(): void {
    this.modalService.openModal(ModalInformationComponent, { information: this.data.modal });
  }

  public hideBanner(): void {
    this.hideAlert.emit(true);
  }

  public verifyBodyLength(body: string): void {
    this.largeBody = body.length > 130 ? true : false;
  }

  public shortenedBody(body: string): string {
    if (this.largeBody && this.bodyStyle === 'small-body' && this.util.isMediumMobile) {
      this.shorterText = true;
      return body.substring(0, 90) + '...';
    } else {
      this.shorterText = false;
      return body;
    }
  }

  private updateShowMoreText(): void {
    this.showMoreText = this.largeBody && this.util.isMediumMobile && this.bodyStyle === 'small-body';
  }

  public setBodyStyle(): void {
    this.bodyStyle = this.bodyStyle === 'large-body' ? 'small-body' : 'large-body';
    this.updateShortenedBody();
    this.updateShowMoreText();
  }
}
