import { Component } from '@angular/core';
import { SwiperOptions } from "swiper/types/swiper-options";

export interface Slide {
  slidesPerView: number;
  loop: boolean;
  autoplay?: { delay: number; };
  initialSlide?: number;
}

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
})
export class SlidesComponent {
  public slideOpts: SwiperOptions = {
    slidesPerView: 1,
    loop: false,
  };

  constructor() { }

}
