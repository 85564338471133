import { MultiFunds, ProfitabilityTable, RestrictionsTable, AssignationTable } from '@interfaces/multi-funds.interface';
import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_MULTI_FUNDS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        header: {
          id: 1,
          pagina: 'Multifondos',
          imagen: 42,
          icono: 'analysis'
        },
        traducciones: [
          {
            rentabilidad: {
              titulos: {
                fondo: 'Fondo',
                mes: 'Oct-20',
                semestre: 'Ene 2020 - Oct 2020',
                añoPasado: '<p>Últimos 12 meses<\/p>\n<p>Nov 2019-Oct 2020<\/p>',
                ultimosAños: '<p>Promedio Anual<\/p>\n<p>Sep 2002 - Oct 2020<\/p>',
                historico: '<p>Promedio Anual<\/p>\n<p>Jun 1981 - Oct 2020<\/p>'
              },
              items: [
                {
                  fondo: 'A',
                  mes: '-1.10%',
                  semestre: '-5.05%',
                  añoPasado: '-1.09%',
                  ultimosAños: '5.55%',
                  historico: ''
                },
                {
                  fondo: 'B',
                  mes: '-0.37%',
                  semestre: '-2.17%',
                  añoPasado: '0.19%',
                  ultimosAños: '5.02%',
                  historico: ''
                },
                {
                  fondo: 'C',
                  mes: '0.21%',
                  semestre: '-0.17%',
                  añoPasado: '1.22%',
                  ultimosAños: '4.85%',
                  historico: '7.99%'
                },
                {
                  fondo: 'D',
                  mes: '0.49%',
                  semestre: '0.63%',
                  añoPasado: '0.65%',
                  ultimosAños: '4.22%',
                  historico: ''
                },
                {
                  fondo: 'E',
                  mes: '0.53%',
                  semestre: '2.12%',
                  añoPasado: '1.12%',
                  ultimosAños: '3.36%',
                  historico: '4.12%'
                }
              ]
            },
            languages_code: 'es'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

const pageKey = 'AFFILIATE.ASSESSMENT.MULTI_FUNDS';

export const MULTI_FUNDS_MOCKUP: MultiFunds = {
  header: {
    id: 1,
    image: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: '',
    },
    icon: 'handshake',
    firstLine: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>',
  },
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  funds: [
    {
      name: 'A',
      risk: `${pageKey}.FUNDS.A.RISK`,
      description: `${pageKey}.FUNDS.A.DESCRIPTION`,
      maxLimit: '80%',
      minLimit: '40%',
    },
    {
      name: 'B',
      risk: `${pageKey}.FUNDS.B.RISK`,
      description: `${pageKey}.FUNDS.B.DESCRIPTION`,
      maxLimit: '60%',
      minLimit: '25%',
    },
    {
      name: 'C',
      risk: `${pageKey}.FUNDS.C.RISK`,
      description: `${pageKey}.FUNDS.C.DESCRIPTION`,
      maxLimit: '40%',
      minLimit: '15%',
    },
    {
      name: 'D',
      risk: `${pageKey}.FUNDS.D.RISK`,
      description: `${pageKey}.FUNDS.D.DESCRIPTION`,
      maxLimit: '20%',
      minLimit: '5%',
    },
    {
      name: 'E',
      risk: `${pageKey}.FUNDS.E.RISK`,
      description: `${pageKey}.FUNDS.E.DESCRIPTION`,
      maxLimit: '5%',
      minLimit: '0%',
    },
  ],
  helpSection: {
    risky: {
      title: `${pageKey}.HELP.RISKY.TITLE`,
      description: `${pageKey}.HELP.RISKY.DESCRIPTION`,
    },
    safe: {
      title: `${pageKey}.HELP.SAFE.TITLE`,
      description: `${pageKey}.HELP.SAFE.DESCRIPTION`,
    }
  },
  fundChangeDisclaimer: `${pageKey}.FUND_CHANGE_DISCLAIMER`,
  moreInfoCards: [
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_restricciones.jpg',
        alt: 'Restricciones'
      },
      title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'restrictions',
        title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
        description: `${pageKey}.CARDS.RESTRICTIONS.MODAL_DESCRIPTION`,
        data: {
          header: {
            type: 'Tipo de Afiliado',
            age: 'Edad',
            funds: 'Fondos a optar',
          },
          items: [
            {
              type: 'No pensionado',
              age: '<p>Hombre hasta 55 años.</p>\n<p>Mujer hasta 50 años.</p>',
              funds: 'A, B, C, D, E',
            },
            {
              type: 'No pensionado',
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujer desde los 51 años.</p>',
              funds: 'B, C, D, E',
            },
            {
              type: 'Pensionado',
              age: 'Cualquier edad',
              funds: 'C, D, E',
            },
          ]
        } as RestrictionsTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_cambios.jpg',
        alt: 'Cambio multifondos'
      },
      title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
        description: `${pageKey}.CARDS.REGULAR_CHANGE.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_eleccion.jpg',
        alt: 'Elección multifondos'
      },
      title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'assignation',
        title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
        description: `${pageKey}.CARDS.ASSIGNATION.MODAL_DESCRIPTION`,
        data: {
          header: {
            age: 'Edad',
            fund: 'Fondos a optar',
          },
          items: [
            {
              age: 'Hombres y mujeres hasta 35 años.',
              fund: 'B',
            },
            {
              age: '<p>Hombres desde 36 a 55 años.</p>\n<p>Mujeres desde 36 hasta los 50 años.</p>',
              fund: 'C',
            },
            {
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujeres desde 51 años.</p>\n<p>Pensionados.</p>',
              fund: 'D',
            },
          ]
        } as AssignationTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_rentabilidad.jpg',
        alt: 'Rentabilidad multifondos'
      },
      title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'profitability',
        title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
        description: `${pageKey}.CARDS.PROFITABILITY.MODAL_DESCRIPTION`,
        data: {
          header: {
            fund: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.FUND`,
            month: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.MONTH`,
            semester: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.SEMESTER`,
            lastYear: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEAR`,
            lastYears: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEARS`,
            historic: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.HISTORIC`,
          },
          items: [
            {
              fund: 'A',
              month: '-0,35%',
              semester: '-5,98%',
              lastYear: '0,42%',
              lastYears: '5,58%',
              historic: '',
            },
            {
              fund: 'B',
              month: '-0,14%',
              semester: '-2,72%',
              lastYear: '1,09%',
              lastYears: '5,06%',
              historic: '',
            },
            {
              fund: 'C',
              month: '-0,41%',
              semester: '-0,57%',
              lastYear: '1,32%',
              lastYears: '4,89%',
              historic: '8,04%',
            },
            {
              fund: 'D',
              month: '-0,42%',
              semester: '1,04%',
              lastYear: '0,14%',
              lastYears: '4,30%',
              historic: '',
            },
            {
              fund: 'E',
              month: '-0,58%',
              semester: '2,41%',
              lastYear: '0,00%',
              lastYears: '3,43%',
              historic: '4,18%',
            },
          ]
        } as ProfitabilityTable,
      }
    },
  ]
};

export const GET_MULTI_FUNDS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        header: {
          id: 1,
          pagina: 'Multifondos',
          imagen: 42,
          icono: 'analysis'
        },
        traducciones: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const MULTI_FUNDS_NULL_MOCKUP: MultiFunds = {
  header: {
    id: 1,
    image: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'handshake',
    firstLine: null,
  },
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  funds: [
    {
      name: 'A',
      risk: `${pageKey}.FUNDS.A.RISK`,
      description: `${pageKey}.FUNDS.A.DESCRIPTION`,
      maxLimit: '80%',
      minLimit: '40%',
    },
    {
      name: 'B',
      risk: `${pageKey}.FUNDS.B.RISK`,
      description: `${pageKey}.FUNDS.B.DESCRIPTION`,
      maxLimit: '60%',
      minLimit: '25%',
    },
    {
      name: 'C',
      risk: `${pageKey}.FUNDS.C.RISK`,
      description: `${pageKey}.FUNDS.C.DESCRIPTION`,
      maxLimit: '40%',
      minLimit: '15%',
    },
    {
      name: 'D',
      risk: `${pageKey}.FUNDS.D.RISK`,
      description: `${pageKey}.FUNDS.D.DESCRIPTION`,
      maxLimit: '20%',
      minLimit: '5%',
    },
    {
      name: 'E',
      risk: `${pageKey}.FUNDS.E.RISK`,
      description: `${pageKey}.FUNDS.E.DESCRIPTION`,
      maxLimit: '5%',
      minLimit: '0%',
    },
  ],
  helpSection: {
    risky: {
      title: `${pageKey}.HELP.RISKY.TITLE`,
      description: `${pageKey}.HELP.RISKY.DESCRIPTION`,
    },
    safe: {
      title: `${pageKey}.HELP.SAFE.TITLE`,
      description: `${pageKey}.HELP.SAFE.DESCRIPTION`,
    }
  },
  fundChangeDisclaimer: `${pageKey}.FUND_CHANGE_DISCLAIMER`,
  moreInfoCards: [
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_restricciones.jpg',
        alt: 'Restricciones'
      },
      title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'restrictions',
        title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
        description: `${pageKey}.CARDS.RESTRICTIONS.MODAL_DESCRIPTION`,
        data: {
          header: {
            type: 'Tipo de Afiliado',
            age: 'Edad',
            funds: 'Fondos a optar',
          },
          items: [
            {
              type: 'No pensionado',
              age: '<p>Hombre hasta 55 años.</p>\n<p>Mujer hasta 50 años.</p>',
              funds: 'A, B, C, D, E',
            },
            {
              type: 'No pensionado',
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujer desde los 51 años.</p>',
              funds: 'B, C, D, E',
            },
            {
              type: 'Pensionado',
              age: 'Cualquier edad',
              funds: 'C, D, E',
            },
          ]
        } as RestrictionsTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_cambios.jpg',
        alt: 'Cambio multifondos'
      },
      title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
        description: `${pageKey}.CARDS.REGULAR_CHANGE.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_eleccion.jpg',
        alt: 'Elección multifondos'
      },
      title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'assignation',
        title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
        description: `${pageKey}.CARDS.ASSIGNATION.MODAL_DESCRIPTION`,
        data: {
          header: {
            age: 'Edad',
            fund: 'Fondos a optar',
          },
          items: [
            {
              age: 'Hombres y mujeres hasta 35 años.',
              fund: 'B',
            },
            {
              age: '<p>Hombres desde 36 a 55 años.</p>\n<p>Mujeres desde 36 hasta los 50 años.</p>',
              fund: 'C',
            },
            {
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujeres desde 51 años.</p>\n<p>Pensionados.</p>',
              fund: 'D',
            },
          ]
        } as AssignationTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_rentabilidad.jpg',
        alt: 'Rentabilidad multifondos'
      },
      title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'profitability',
        title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
        description: `${pageKey}.CARDS.PROFITABILITY.MODAL_DESCRIPTION`,
        data: {
          header: {
            fund: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.FUND`,
            month: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.MONTH`,
            semester: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.SEMESTER`,
            lastYear: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEAR`,
            lastYears: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEARS`,
            historic: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.HISTORIC`,
          },
          items: [
            {
              fund: 'A',
              month: '-0,35%',
              semester: '-5,98%',
              lastYear: '0,42%',
              lastYears: '5,58%',
              historic: '',
            },
            {
              fund: 'B',
              month: '-0,14%',
              semester: '-2,72%',
              lastYear: '1,09%',
              lastYears: '5,06%',
              historic: '',
            },
            {
              fund: 'C',
              month: '-0,41%',
              semester: '-0,57%',
              lastYear: '1,32%',
              lastYears: '4,89%',
              historic: '8,04%',
            },
            {
              fund: 'D',
              month: '-0,42%',
              semester: '1,04%',
              lastYear: '0,14%',
              lastYears: '4,30%',
              historic: '',
            },
            {
              fund: 'E',
              month: '-0,58%',
              semester: '2,41%',
              lastYear: '0,00%',
              lastYears: '3,43%',
              historic: '4,18%',
            },
          ]
        } as ProfitabilityTable,
      }
    },
  ]
};
